import React from "react";
import { Link } from "react-router-dom";
import Heading from "../components/Heading";
import Posts from "../components/Posts";
import "./Home.css";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="home">
      <div className="home_main_section">
        <h1 className="home_main_section_heading_top">Bestpol CD</h1>
        <h1 className="home_main_section_heading_bottom">{t("home_main")}</h1>
      </div>
    </div>
  );
}
