import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_left">
        <p>&copy; Bestpol CD / All rights reserved</p>
      </div>
      <div className="footer_right">
        {/* //TODO:link telefon i mail */}
        <p>+48 507 780 605</p>
        <p>rr@bestpolcd.pl</p>
      </div>
    </footer>
  );
}
