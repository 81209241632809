import React, { useEffect, useState } from "react";
import "./PracaSzczegoly.css";
import { BsFillCircleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { GoPrimitiveDot } from "react-icons/go";

export default function Praca() {
  const { id } = useParams();
  const [job, setJob] = useState();

  useEffect(() => {
    db.collection("job")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        setJob(querySnapshot.data());
      });
  }, []);

  return (
    <div className="praca">
      <div className="praca_image1">
        <h1>SZUKASZ PRACY?</h1>
        <p>ar@bestpolcd.pl</p>
        <p>+ (48) 502 363 508</p>
      </div>

      <div className="praca_section1">
        <div className="praca_section1_heading">
          {job ? (
            <p className="praca_section1_mainp">{job?.name}</p>
          ) : (
            <p className="praca_section1_mainp">Ładowanie...</p>
          )}
        </div>
      </div>
      <div className="praca_section2">
        <div className="praca_section2_containers">
          <div className="praca_section2_containers2">
            {job?.stanowisko && <h3>stanowisko</h3>}
            <ul>
              {job?.stanowisko?.map((element) => {
                return (
                  <li>
                    <GoPrimitiveDot
                      size={10}
                      className="praca_section2_containers2_dott"
                    />
                    {element}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="praca_section2_containers">
          <div className="praca_section2_containers2">
            {job?.zakres && <h3>zakres obowiązków</h3>}
            <ul>
              {job?.zakres?.map((element) => {
                return (
                  <li>
                    <GoPrimitiveDot
                      size={10}
                      className="praca_section2_containers2_dott"
                    />
                    {element}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="praca_section2_containers">
          <div className="praca_section2_containers2">
            {job?.wymagania && <h3>nasze wymagania</h3>}
            <ul>
              {job?.wymagania?.map((element) => {
                return (
                  <li>
                    <GoPrimitiveDot
                      size={10}
                      className="praca_section2_containers2_dott"
                    />
                    {element}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="praca_section2_containers">
          <div className="praca_section2_containers2">
            {job?.oferujemy && <h3>to oferujemy</h3>}
            <ul>
              {job?.oferujemy?.map((element) => {
                return (
                  <li>
                    <GoPrimitiveDot
                      size={10}
                      className="praca_section2_containers2_dott"
                    />
                    {element}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
