import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./ProjektSzczegoly.css";
import { BiArrowBack } from "react-icons/bi";
import { useEffect } from "react";
import { db } from "../firebase";

export default function ProjektSzczegoly() {
  const { id } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    db.collection("projects")
      .doc(id)
      .get()
      .then((querySnapshot) => {
        setProject(querySnapshot.data());
      });
  }, []);

  return (
    <div className="projektszczegoly">
      <Link className="projektszczegoly_details_arrow" to={"/projekty"}>
        <BiArrowBack></BiArrowBack>
      </Link>
      <div className="projektszczegoly_details">
        <section>
          <Link to="/projekty">
            <img src={project?.img || "../loading.gif"} alt="" />
          </Link>
        </section>
        <section>
          <h2>{project?.title || "Ładowanie..."}</h2>
          <p>{project?.desc}</p>
        </section>
      </div>
    </div>
  );
}
