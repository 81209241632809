import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Onas from "./pages/Onas";
import Oferta from "./pages/Oferta";
import Projekty from "./pages/Projekty";
import Kontakt from "./pages/Kontakt";
import Praca from "./pages/Praca";
import ProjektSzczegoly from "./pages/ProjektSzczegoly";
import PracaSzczegoly from "./pages/PracaSzczegoly";
import { useEffect } from "react";

function App() {
  const AutoScroll = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return <></>;
  };

  const location = useLocation();
  return (
    <div className="App">
      <AutoScroll />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/o-nas" element={<Onas />} />
        <Route path="/oferta" element={<Oferta />} />
        <Route path="/projekty" element={<Projekty />} />
        <Route path="/praca" element={<Praca />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/projekty/:id" element={<ProjektSzczegoly />} />
        <Route path="/praca/:id" element={<PracaSzczegoly />} />
      </Routes>

      {location.pathname !== "/projekty" ? <Footer /> : ""}
    </div>
  );
}

export default App;
