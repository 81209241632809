import React from "react";
import "./Section.css";

export default function Section({ title, textLeft, textRight }) {
  return (
    <div className="section">
      {title && (
        <div className="section_heading">
          <p>{title}</p>
        </div>
      )}
      <div
        className="section_content"
        style={{ marginTop: !title ? "10vh" : "" }}
      >
        <div className="section_block">
          <p className="section_p">{textLeft}</p>
        </div>
        <div className="section_block">
          <p className="section_p">{textRight}</p>
        </div>
      </div>
    </div>
  );
}
