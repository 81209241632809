import React from "react";
import Heading from "../components/Heading";
import Section from "../components/Section";
import "./Onas.css";
import { GrUserWorker } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";
import { ImEarth } from "react-icons/im";
import { useTranslation } from "react-i18next";

export default function Onas() {
  const { t } = useTranslation();

  return (
    <div className="o-nas">
      <Heading
        text={t("onas_heading").toUpperCase()}
        img={
          "https://firebasestorage.googleapis.com/v0/b/bestpol-64906.appspot.com/o/assets%2Fo-nasImage1.png?alt=media&token=0eb62b03-c0b5-4f9d-8e0a-1a553b6c0644"
        }
      />
      <Section
        // title={"Praca od 13 lat"}
        textLeft={t("onas_content1_left")}
        textRight={t("onas_content1_right")}
      />

      <div className="o-nas_image"></div>

      <Section
        // title={"Nowoczesny sprzęt"}
        textLeft={t("onas_content2_left")}
        textRight={t("onas_content2_right")}
      />
    </div>
  );
}
