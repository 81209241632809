import React from "react";
import "./Heading.css";

export default function Heading({ img, text, size }) {
  return (
    <div
      className="heading"
      style={{ backgroundImage: `url(${img})`, height: size || "95vh" }}
    >
      <h2>{text}</h2>
    </div>
  );
}
