import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import _pl from "./locale/pl.json";
import _de from "./locale/de.json";
import _en from "./locale/en.json";

// Translations
const resources = {
  pl: {
    translation: _pl,
  },
  de: {
    translation: _de,
  },
  en: {
    translation: _en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pl",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
