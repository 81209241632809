import React, { useEffect, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import "./Praca.css";
import { useTranslation } from "react-i18next";

export default function Praca() {
  const [jobs, setJobs] = useState();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    let jobsData = [];
    db.collection("job")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          jobsData.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setJobs(jobsData);
      });
  }, []);

  console.log(jobs);

  return (
    <div className="praca">
      <div className="praca_image">
        <h1>{t("jobs_heading")}</h1>
        <p>ar@bestpolcd.pl</p>
        <p>+ (48) 502 363 508</p>
      </div>
      <div className="praca_links">
        <h2 className="praca_heading">{t("jobs_second_heading")}</h2>
        {i18n.language == "pl" &&
          jobs?.map((job) => {
            return (
              <li>
                <Link to={`/praca/${job.id}`}>{job.name}</Link>
              </li>
            );
          })}
      </div>
    </div>
  );
}
