import React from "react";
import "./Projekty.css";
import Slider from "../components/Slider/Slider";

export default function Projekty() {
  return (
    <div className="projekty">
      <Slider />
    </div>
  );
}
