import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBd_dAVqNVae1lt5cwXv1CzXu3qwzWhrRA",
  authDomain: "bestpol-64906.firebaseapp.com",
  projectId: "bestpol-64906",
  storageBucket: "bestpol-64906.appspot.com",
  messagingSenderId: "181043426775",
  appId: "1:181043426775:web:0579f6552ddafcff900792",
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore as db, timestamp };
